import { Provider } from "react-redux"
import store from "./src/redux/store"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
// import { Adapter } from "./src/components/Builder/Components/LoginAdapter/Adapter"
const React = require("react")

// Wraps every page in a component
let GOOGLE = ""
let WebSiteJava = ""
export const wrapPageElement = ({ element, props }) => {
  GOOGLE = props.pageContext.googleAnalyticsCode

  let persistor = persistStore(store)
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )
}

const appendThirdPartyScripts = () => {
  GOOGLE = GOOGLE && GOOGLE !== "string" && GOOGLE.split(",")
  // React.useEffect(() => {
  // console.log("here")
  // appendScript(
  //   `
  // window.dataLayer = window.dataLayer || [];
  // function gtag(){dataLayer.push(arguments);}
  // gtag('js', new Date());
  // gtag('config', '${GOOGLE[0]}');`,
  //   "textContent"
  // )

  // if (GOOGLE && GOOGLE[0]) {
  //   appendScript(
  //     `https://www.googletagmanager.com/gtag/js?id=${GOOGLE[0]}`,
  //     "src"
  //   )
  // }

  // if (WebSiteJava) {
  //   appendScript(WebSiteJava, "src", "async")
  // }

  sessionStorage.setItem("loadedScripts", true)
}
const appendScript = (scriptInnerHTML, type, load) => {
  const scriptElement = document.createElement("script")
  scriptElement[type] = scriptInnerHTML
  scriptElement[load === "defer" ? "defer" : "async"] = true
  document.body.appendChild(scriptElement)
}
// const loadedScripts = sessionStorage.getItem("loadedScripts")
// const appendScriptsOnScrollOptions = { once: true }

// export const onInitialClientRender = () => {
//   if (!loadedScripts) {
//     window.addEventListener(
//       "mousemove",
//       appendThirdPartyScripts,
//       appendScriptsOnScrollOptions
//     )
//   } else {
//     appendThirdPartyScripts()
//   }
// }

// export const onRouteUpdate = ({ prevLocation }) => {
//   if (prevLocation && !loadedScripts) {
//     window.removeEventListener(
//       "mousemove",
//       appendThirdPartyScripts,
//       appendScriptsOnScrollOptions
//     )
//     // appendThirdPartyScripts()
//   }
// }
